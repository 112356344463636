import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import TooglePadrao from "../../../componentes/Botoes/TooglePadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import TituloCadastroDetalhe from "../../../componentes/Titulos/TituloCadatrosDetalhe";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import ComboBoxPadrao from "../../../componentes/ComboBox/ComboboxPadrão"
import ImputNumeroPadrao from "../../../componentes/Inputs/ImputNumeroPadrao"
import Rodape from "../../../componentes/Rodape";
import { Global_Retorna_Permissoes, Global_Retorna_Usuario } from "../../../Funcoes_Globais";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';

//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class PlanosCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',
            
            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),
            State_Login_Revenda: Global_Retorna_Usuario('login_revenda'),

            state_lista_revendas: [],
            state_lista_pcf: [],

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao ,
            state_plano_id: this.props.match.paramsLocation.state.plano_id,            
            state_plano_descricao: '',
            state_plano_valor: 0,
            state_plano_repasse: 0,
            state_plano_ativo: 'S',
            state_plano_fk_revenda: 0,
            state_plano_fk_pcf: 0,

        };

        this.Escolhe_Revenda = this.Escolhe_Revenda.bind(this);
        this.Escolhe_Pcf = this.Escolhe_Pcf.bind(this);
        this.Retorna_Listar_Diversas = this.Retorna_Listar_Diversas.bind(this);
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.cliente_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_plano_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais()                  
    };

    Funcoes_Iniciais = async () =>{
        //Precisa esperar o retorno da função Retorna_Listar_Diversas
        await this.Retorna_Listar_Diversas();
        if (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'ver') {
            this.Pesquisar_Plano();
        }
    }    

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            //
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };    
    
    Retorna_Listar_Diversas(){
        return new Promise((resolve) => {
            var objJson = { 
                revenda: true,
                retorna_plano_contas_financeiro_entrada: true,   
            };
    
            this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
            Api.Executa_chamada(
                this.props.navegar, 'Retorna_Listas_Diversas', 0, JSON.stringify(objJson)
            ).then((resposta) => {
                resolve(true);
                this.Fecha_LoadPopUpPadrao();
                if (resposta.status === 1){
                    //console.log(resposta.dados.revendas)
                    //console.log(resposta.dados)
                    this.setState({
                        state_lista_revendas: resposta.dados.revendas,
                        state_lista_pcf: resposta.dados.plano_contas_financeiro,
                    }, function (){
                        if ((resposta.dados.revendas) && (this.state.State_Login_Revenda) && (this.state.state_status_operacao === 'cadastrar')){
                            this.setState({
                                state_clientes_cod_revenda: resposta.dados.revendas[0].codigo
                            })
                        }
                    });
                }else if (resposta.status === 2){
                    this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
                }
                else{
                    this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
                };           
            });            
        });
    };  

    Pesquisar_Plano(){
        var objJson = { 
            Pesq_Codigo: this.state.state_plano_id,
        };
        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        
        Api.Executa_chamada(
            this.props.navegar, 'Plano_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {

            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_plano_id: resposta.dados.plano.codigo,
                    state_plano_descricao: resposta.dados.plano.descricao,
                    state_plano_valor: resposta.dados.plano.valor_plano,
                    state_plano_repasse: resposta.dados.plano.valor_repasse,
                    state_plano_fk_revenda: resposta.dados.plano.fk_revenda,
                    state_plano_ativo: resposta.dados.plano.ativo,
                    state_plano_fk_pcf: resposta.dados.plano.fk_plano_contas_financeiro,
                }, function (){
                    //
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Plano(){
        //valida os campos
        //if (!this.state.state_clientes_tipo_pessoa || this.state.state_clientes_tipo_pessoa === 0){
        //    this.Abre_MsgPopUpPadrao(3, false, 'Tipo de Pessoa Inválida!', '', 1000);
        //    return
        //}

        if (!this.state.state_plano_descricao || this.state.state_plano_descricao.trim === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'Descrição do plano inválida!', '', 1000);
            return
        }
        if (!this.state.state_plano_valor || this.state.state_plano_valor <= 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Valor do plano inválido!', '', 1000);
            return
        } 
        if (!this.state.state_plano_repasse || this.state.state_plano_repasse <= 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Valor do repasse inválido!', '', 1000);
            return
        }
        if (!this.state.state_plano_fk_revenda || this.state.state_plano_fk_revenda <= 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Escolha a Revenda!', '', 1000);
            return
        }
        if (!this.state.state_plano_fk_pcf || this.state.state_plano_fk_pcf <= 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Escolha o Plano de Contas!', '', 1000);
            return
        }        

        var objJson = { 
            plano_id: this.state.state_plano_id,
            plano_descricao: this.state.state_plano_descricao,
            plano_valor: this.state.state_plano_valor,
            plano_repasse: this.state.state_plano_repasse,
            plano_fk_revenda: this.state.state_plano_fk_revenda,
            plano_ativo: this.state.state_plano_ativo,
            plano_fk_pcf: this.state.state_plano_fk_pcf,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Plano_Atu_Cadastra', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                if (this.state.state_status_operacao === 'cadastrar'){
                    this.setState({
                        state_plano_id: resposta.dados.plano_id,
                        state_status_operacao: 'editar',
                    }, ()=>{
                        this.Pesquisar_Plano();
                        this.Abre_MsgPopUpPadrao(1, false, 'Plano cadastrado com sucesso!', '', 1500);
                    });
                }else{
                    this.Abre_MsgPopUpPadrao(1, false, 'Cadastro atualizado com sucesso!', '', 1500);
                }
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 1500)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 1500)
            };           
        });        
    }

    Escolhe_Revenda(e){
        e.preventDefault();
        this.setState({
            state_plano_fk_revenda: parseInt(e.target.value)
        });
    }  
    
    Escolhe_Pcf(e){
        e.preventDefault();
        this.setState({
            state_plano_fk_pcf: parseInt(e.target.value)
        });
    }     
        
    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Planos > Cadastro"} />
                    <TituloCadastroDetalhe titulo={"Geral"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao 
                            titulo={'*Descrição do Plano'}
                            onChange = {valor => this.setState({state_plano_descricao: valor.target.value})}
                            value = {this.state.state_plano_descricao}
                            maxLength = {100}   
                        />
                        <ImputNumeroPadrao
                            titulo = 'Valor do Plano'
                            prefix = 'R$ '
                            value={this.state.state_plano_valor}
                            onValueChange = {valor => this.setState({state_plano_valor: valor.value})}
                        /> 
                        <ImputNumeroPadrao
                            titulo = 'Valor do Repasse'
                            prefix = 'R$ '
                            value={this.state.state_plano_repasse}
                            onValueChange = {valor => this.setState({state_plano_repasse: valor.value})}
                        />
                        {
                            this.state.State_Login_Revenda === false ?
                            <ComboBoxPadrao
                                titulo = {'*Revenda'}
                                dica = {'Escolha a Revenda'}
                                itens = {this.state.state_lista_revendas}
                                onChange = {this.Escolhe_Revenda}
                                select_value = {this.state.state_plano_fk_revenda}
                            />
                            : null
                        }
                        <ComboBoxPadrao
                            titulo = {'*Plano de Contas'}
                            dica = {'Nenhum'}
                            itens = {this.state.state_lista_pcf}
                            onChange = {this.Escolhe_Pcf}
                            select_value = {this.state.state_plano_fk_pcf}
                            //disabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                        />                                                 
                        <TooglePadrao
                            descricao={'Ativo'}
                            marcado={this.state.state_plano_ativo}
                            onClick={() => this.setState({state_plano_ativo: (this.state.state_plano_ativo === 'S') ? 'N' : 'S'})}
                        />                                                                        
                    </div>                                    
                    <div className={Estilos.ConteinerBotoes} >
                        <BotaoPadrao 
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar(-1)}} 
                        />

                        {
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar')) ?
                            <BotaoPadrao
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_Plano()}} 
                            />
                            : null
                        }                 
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(PlanosCadastro);