import React, { Component } from "react";
import BarraLateral from "../../../../componentes/BarraLateral";
import InputPadrao from "../../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../../componentes/Botoes/BotaoPadrao";
import TooglePadrao from "../../../../componentes/Botoes/TooglePadrao";
import TituloPagina from "../../../../componentes/TituloPagina";
import TituloCadastroDetalhe from "../../../../componentes/Titulos/TituloCadatrosDetalhe";
import MsgPopUpPadrao from "../../../../componentes/PopUpModal/MsgPopUpPadrao";
import ComboBoxPadrao from "../../../../componentes/ComboBox/ComboboxPadrão"
//import ImputNumeroPadrao from "../../../../componentes/Inputs/ImputNumeroPadrao"
import Rodape from "../../../../componentes/Rodape";
import { Global_Retorna_Permissoes, Global_Retorna_Usuario } from "../../../../Funcoes_Globais";
import withRouter from "../../../../Rotas/rotasParametros";
import Api from '../../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../../componentes/PopUpLoad/PopUpLoadPadrao';

//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class PlanoContasResultadoGrupoCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',
            
            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),
            State_Login_Revenda: Global_Retorna_Usuario('login_revenda'),

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao ,
            state_plano_id: this.props.match.paramsLocation.state.plano_id,            
            state_plano_descricao: '',
            state_plano_ativo: 'S',
            state_plano_sequencia: 0,
            state_plano_fk_contas_resultado: 0,

            state_lista_plano_contas_resultado: [],

            state_lista_sequencia: [],
        };

        this.Escolhe_Sequencia = this.Escolhe_Sequencia.bind(this);
        this.Retorna_Listar_Diversas = this.Retorna_Listar_Diversas.bind(this);
        this.Escolhe_Conta_Resultado = this.Escolhe_Conta_Resultado.bind(this);
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.cliente_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_plano_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais()                  
    };

    Funcoes_Iniciais = async () =>{
        //Precisa esperar o retorno da função Retorna_Listar_Diversas
        await this.Retorna_Listar_Diversas();

        if (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'ver') {
            this.Pesquisar_Plano();
        }

        for (let i = 1; i < 501; i++) {
            this.setState(prevState => ({
                state_lista_sequencia: [...prevState.state_lista_sequencia, {codigo: i, descricao: `${i}`}]
              }))        
        }        
    }
    
    Retorna_Listar_Diversas(){
        return new Promise((resolve) => {
            var objJson = { 
                retorna_plano_contas_resultado: true,   
            };
    
            this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
            Api.Executa_chamada(
                this.props.navegar, 'Retorna_Listas_Diversas', 0, JSON.stringify(objJson)
            ).then((resposta) => {
                resolve(true);
                this.Fecha_LoadPopUpPadrao();
                if (resposta.status === 1){
                    console.log(resposta.dados.plano_contas_resultado)
                    //console.log(resposta.dados)
                    this.setState({
                        state_lista_plano_contas_resultado: resposta.dados.plano_contas_resultado,
                    }, function (){
                        //if ((resposta.dados.revendas) && (this.state.State_Login_Revenda) && (this.state.state_status_operacao === 'cadastrar')){
                        //    this.setState({
                        //        state_clientes_cod_revenda: resposta.dados.revendas[0].codigo
                        //    })
                        //}
                    });
                }else if (resposta.status === 2){
                    this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
                }
                else{
                    this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
                };           
            });            
        });
    }; 
    
    Escolhe_Conta_Resultado(e){
        e.preventDefault();
        this.setState({
            state_plano_fk_contas_resultado: parseInt(e.target.value),
        });
    } 

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            //
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };
    
    Escolhe_Sequencia(e){
        e.preventDefault();
        this.setState({
            state_plano_sequencia: parseInt(e.target.value)
        });
    }      

    Pesquisar_Plano(){
        var objJson = { 
            Pesq_Codigo: this.state.state_plano_id,
        };
        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        
        Api.Executa_chamada(
            this.props.navegar, 'Plano_Contas_Resultado_Grupo_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {

            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                console.log(resposta.dados)
                this.setState({
                    state_plano_id: resposta.dados.plano.codigo,
                    state_plano_descricao: resposta.dados.plano.descricao,
                    state_plano_sequencia: resposta.dados.plano.sequencia,
                    state_plano_ativo: resposta.dados.plano.ativo,
                    state_plano_fk_contas_resultado: resposta.dados.plano.fk_contas_resultado,
                }, function (){
                    //
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Plano(){
        //valida os campos
        //if (!this.state.state_clientes_tipo_pessoa || this.state.state_clientes_tipo_pessoa === 0){
        //    this.Abre_MsgPopUpPadrao(3, false, 'Tipo de Pessoa Inválida!', '', 1000);
        //    return
        //}

        if (!this.state.state_plano_descricao || this.state.state_plano_descricao.trim === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'Descrição do grupo inválida!', '', 1000);
            return
        }

        if (!this.state.state_plano_fk_contas_resultado || this.state.state_plano_fk_contas_resultado === 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Conta Principal Inválida!', '', 1000);
            return
        }        

        if (!this.state.state_plano_sequencia || this.state.state_plano_sequencia === 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Sequência do grupo inválida!', '', 1000);
            return;
        }         

        var objJson = { 
            plano_id: this.state.state_plano_id,
            plano_descricao: this.state.state_plano_descricao,
            plano_sequencia: this.state.state_plano_sequencia,
            plano_fk_contas_resultado: this.state.state_plano_fk_contas_resultado,
            plano_ativo: this.state.state_plano_ativo,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Plano_Contas_Resultado_Grupo_Atu_Cadastra', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                if (this.state.state_status_operacao === 'cadastrar'){
                    this.setState({
                        state_plano_id: resposta.dados.plano_id,
                        state_status_operacao: 'editar',
                    }, ()=>{
                        this.Pesquisar_Plano();
                        this.Abre_MsgPopUpPadrao(1, false, 'Plano cadastrado com sucesso!', '', 1500);
                    });
                }else{
                    this.Abre_MsgPopUpPadrao(1, false, 'Cadastro atualizado com sucesso!', '', 1500);
                }
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 1500)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 1500)
            };           
        });        
    }   
        
    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Planos de Contas Grupo > Cadastro"} />
                    <TituloCadastroDetalhe titulo={"Geral"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao 
                            titulo={'*Descrição'}
                            onChange = {valor => this.setState({state_plano_descricao: valor.target.value})}
                            value = {this.state.state_plano_descricao}
                            maxLength = {100}   
                        />
                        <ComboBoxPadrao
                            titulo = {'*Conta Principal'}
                            dica = {'Nenhuma'}
                            itens = {this.state.state_lista_plano_contas_resultado}
                            onChange = {this.Escolhe_Conta_Resultado}
                            select_value = {this.state.state_plano_fk_contas_resultado}
                        />                        
                        <ComboBoxPadrao
                            titulo = {'*Sequência'}
                            dica = {'Nenhum'}
                            itens = {this.state.state_lista_sequencia}
                            onChange = {this.Escolhe_Sequencia}
                            select_value = {this.state.state_plano_sequencia}
                        />                        
                       
                        <TooglePadrao
                            descricao={'Ativo'}
                            marcado={this.state.state_plano_ativo}
                            onClick={() => this.setState({state_plano_ativo: (this.state.state_plano_ativo === 'S') ? 'N' : 'S'})}
                        />                                                                        
                    </div>                                    
                    <div className={Estilos.ConteinerBotoes} >
                        <BotaoPadrao 
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar(-1)}} 
                        />

                        {
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar')) ?
                            <BotaoPadrao
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_Plano()}} 
                            />
                            : null
                        }                 
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(PlanoContasResultadoGrupoCadastro);