//import { FaPlus, FaSearch, FaSave, FaBackward, FaEdit } from "react-icons/fa";
import BotaoPadrao from "../../../Botoes/BotaoPadrao";
import { CoresGlobal } from "../../../../estilos"

import Estilo from "./estilo.module.css";
import React from "react";

//const TabelaTipo1 = ({id, titulo, Itens, onClick}) =>{

var codigo_interno = 0

function ItemTabelaPadrao ({titulo, Itens, motrarBtnEditar, motrarBtnVisualizar, 
    motrarBtnExcluir, clickVisualizar, clickExcluir, clickEditar}) {        
    return(
        <div className={Estilo.Item_Pesquisa} style={{borderLeft: `0.2em solid ${CoresGlobal.cor_paleta_1}`}}>
            
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0}}>                   
                <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                    <div  style={{width: '100%'}}>
                        <p style={{fontSize: '.8em', fontWeight: 'bold', color: CoresGlobal.cor_paleta_1}}>{titulo}</p>
                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 2fr))', gap: '0em', justifyContent: 'space-between'}}>
                            {
                                Itens && Object.entries(Itens.sort((a, b) => (a.item_tabela_padrao_seq > b.item_tabela_padrao_seq) ? 1 : -1)).map(([key, item_principal]) => {
                                    codigo_interno = codigo_interno + 1
                                    return(
                                        <div key={codigo_interno} style={{fontSize: '.7em'}}>
                                            {
                                                Object.keys(item_principal).map((keyName, i) => {
                                                    return(
                                                    <>
                                                        {
                                                            keyName !== 'item_tabela_padrao_seq' ?
                                                            <p><span style={{fontWeight: 'bold'}}>{keyName}</span>: {item_principal[keyName]}</p>
                                                            : null
                                                        }
                                                    </>
                                                    )
                                                })
                                            }
                                        </div>
                                    )                                 
                                  })
                            }                            
                            {//Itens && Object.keys(Itens).map((keyName, i) => {
                             //   codigo_interno = codigo_interno + 1
                             //   return(
                             //   <div key={codigo_interno} style={{fontSize: '.7em'}}>
                             //       <p><span style={{fontWeight: 'bold'}}>{keyName}</span>: {Itens[keyName]}</p>
                             //   </div>
                             //   )
                            //}).sort((a, b) => a.indice_contador - b.indice_contador)
                            }
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    {
                        motrarBtnVisualizar ?
                        <BotaoPadrao
                            estilo={{padding: '0 0.5em'}}
                            tipo={'visualizar'} 
                            onClick={clickVisualizar}
                        />                        
                        : null
                    }
                    {
                        motrarBtnExcluir ?
                        <BotaoPadrao
                            estilo={{padding: '0 0.5em'}}
                            tipo={'excluir'} 
                            onClick={clickExcluir}
                        />                       
                        : null
                    }
                    {
                        motrarBtnEditar ?
                        <BotaoPadrao
                        estilo={{padding: '0 0.5em'}}
                            tipo={'editar'} 
                            onClick={clickEditar}
                        />                      
                        : null
                    }                                                                                 
                </div>
                
            </div>
        </div>
    );
};

export default ItemTabelaPadrao;