import React, { Component } from "react";
import BarraLateral from "../../../../componentes/BarraLateral";
import TabelaTipo1 from "../../../../componentes/Tabelas/TabelaTipo1";
//import InputPadrao from "../../../../componentes/Inputs/ImputPadrao";
import TituloPagina from "../../../../componentes/TituloPagina";
//import BotaoPadrao from "../../../../componentes/Botoes/BotaoPadrao";
import MsgPopUpPadrao from "../../../../componentes/PopUpModal/MsgPopUpPadrao";
//import TabelaPadrao from "../../../../componentes/Tabelas/TabelaPadrao";
//import Paginacao from "../../../../componentes/Tabelas/Paginacao";
import Rodape from "../../../../componentes/Rodape";
import withRouter from "../../../../Rotas/rotasParametros";
import Api from '../../../../servicos/Axios';
import PopUpLoadPadrao from '../../../../componentes/PopUpLoad/PopUpLoadPadrao';
import { Global_Retorna_Permissoes } from "../../../../Funcoes_Globais"
import Estilos from "./estilo.module.css";
import { CoresGlobal } from "../../../../estilos";

//<Link to={"/clientes/cadastro"}  state={{ testea: "value" }}>Teste com parâmetro</Link>

class RelatorioFinanceiroDre extends Component{
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',

            State_Pesq_Valor: '2025',

            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),

            State_lista_Dre: [],  
        }
        this.Pesquisar_Plano = this.Pesquisar_Plano.bind(this);
    };   

    componentDidMount(){
        this.Pesquisar_Plano();
    }

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };

    Pesquisar_Plano(Offset = 0){
        this.setState({
            State_lista_Dre: []
        });

        var objJson = { 
            Pesq_Ano: this.state.State_Pesq_Valor,
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Teste_1', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            console.log(resposta.status)
            if (resposta.status === 1){
                console.log(resposta.dados)
                this.setState({State_lista_Dre: resposta.dados})
            }else if (resposta.status === 2){
                //                          
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }

    render(){
        return(
        <div>    
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Relatório DRE"} />
                    <div> 
                        {
                            //this.state.State_Permissao_cadastrar ?
                            //<BotaoPadrao
                            //    tipo={"adicionar"}
                            //    descricao={"Novo"}
                            //    //onClick={() =>{this.()}}
                            ///>
                            //: null                            
                        }
                    </div>                                     
                    <TabelaTipo1
                        estilo = {{marginTop: '0px'}}
                        transbordarX = {true}
                        titulo = {''}
                    >
                        <table>
                            <thead 
                                className={Estilos.Teste}
                                style={{backgroundColor: CoresGlobal.cor_paleta_7, color: '#fff', fontSize: '16px'}}
                            >
                                <tr>
                                    <th style={{padding: '8px'}}>Resultado</th>
                                    <th style={{padding: '8px'}}>Janeiro</th>
                                    <th style={{padding: '8px'}}>Fevereiro</th>
                                    <th style={{padding: '8px'}}>Março</th>
                                    <th style={{padding: '8px'}}>Abril</th>
                                    <th style={{padding: '8px'}}>Maio</th>
                                    <th style={{padding: '8px'}}>Junho</th>
                                    <th style={{padding: '8px'}}>Julho</th>
                                    <th style={{padding: '8px'}}>Agosto</th>
                                    <th style={{padding: '8px'}}>Setembro</th>
                                    <th style={{padding: '8px'}}>Outubro</th>
                                    <th style={{padding: '8px'}}>Novembro</th>
                                    <th style={{padding: '8px'}}>Dezembro</th>
                                    <th style={{padding: '8px'}}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.State_lista_Dre.map((item) =>{                               
                                    return(
                                        <>
                                        {
                                            item.itens_grupo.map((item_grupo) =>{
                                                return <>
                                                <tr style={{backgroundColor: CoresGlobal.cor_paleta_2, color: '#fff', fontWeight: 'bold', fontSize: '14px'}}>
                                                    <td style={{padding: '5px', minWidth: '500px'}}>{item_grupo.descricao}</td>
                                                    <td>{item_grupo.mes_1}</td>
                                                    <td>{item_grupo.mes_2}</td>
                                                    <td>{item_grupo.mes_3}</td>
                                                    <td>{item_grupo.mes_4}</td>
                                                    <td>{item_grupo.mes_5}</td>
                                                    <td>{item_grupo.mes_6}</td>
                                                    <td>{item_grupo.mes_7}</td>
                                                    <td>{item_grupo.mes_8}</td>
                                                    <td>{item_grupo.mes_9}</td>
                                                    <td>{item_grupo.mes_10}</td>
                                                    <td>{item_grupo.mes_11}</td>
                                                    <td>{item_grupo.mes_12}</td>
                                                    <td>{item_grupo.total}</td>
                                                </tr>
                                                {
                                                    item_grupo.itens_financeiro.map((itens_financeiro) =>{
                                                        return <>
                                                        <tr style={{fontSize: '12px'}}>
                                                            <td>{   
                                                                    itens_financeiro.tipo === 1 ?
                                                                        `---${itens_financeiro.classificacao}-${itens_financeiro.descricao}`
                                                                    :`#${itens_financeiro.classificacao}-${itens_financeiro.descricao}`

                                                            }</td>
                                                            <td style={{padding: '5px'}}>{itens_financeiro.mes_1}</td>
                                                            <td>{itens_financeiro.mes_2}</td>
                                                            <td>{itens_financeiro.mes_3}</td>
                                                            <td>{itens_financeiro.mes_4}</td>
                                                            <td>{itens_financeiro.mes_5}</td>
                                                            <td>{itens_financeiro.mes_6}</td>
                                                            <td>{itens_financeiro.mes_7}</td>
                                                            <td>{itens_financeiro.mes_8}</td>
                                                            <td>{itens_financeiro.mes_9}</td>
                                                            <td>{itens_financeiro.mes_10}</td>
                                                            <td>{itens_financeiro.mes_11}</td>
                                                            <td>{itens_financeiro.mes_12}</td>
                                                            <td>{itens_financeiro.total}</td>
                                                        </tr>
                                                        </>
                                                    })                                                        
                                                }
                                                </>

                                            })
                                        }
                                        <tr style={{backgroundColor: CoresGlobal.cor_paleta_9, color: '#fff', fontWeight: 'bold', fontSize: '18px'}}>
                                            <td style={{padding: '8px'}}>
                                                {item.descricao}
                                            </td>
                                            <td>{item.mes_1}</td>
                                            <td>{item.mes_2}</td>
                                            <td>{item.mes_3}</td>
                                            <td>{item.mes_4}</td>
                                            <td>{item.mes_5}</td>
                                            <td>{item.mes_6}</td>
                                            <td>{item.mes_7}</td>
                                            <td>{item.mes_8}</td>
                                            <td>{item.mes_9}</td>
                                            <td>{item.mes_10}</td>
                                            <td>{item.mes_11}</td>
                                            <td>{item.mes_12}</td>
                                            <td>{item.total}</td>
                                        </tr>                                        

                                        </>
                                    )                            
                                })                                
                                }
                            </tbody>
                        </table>
                      
                    </TabelaTipo1>  
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(RelatorioFinanceiroDre);