import React, { Component } from "react";
import { FaWhatsapp, FaCheck } from "react-icons/fa";
import BarraLateral from "../../../componentes/BarraLateral";
//import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import InputDataPadrao from "../../../componentes/Inputs/InputDataPadrao";
import InputSelectPadrao from "../../../componentes/Inputs/InputSelectPadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
//import TabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao";
import TabelaTipo1 from "../../../componentes/Tabelas/TabelaTipo1";
//import ItemTabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao/ItemTabelaPadrao";
import Paginacao from "../../../componentes/Tabelas/Paginacao";
import Rodape from "../../../componentes/Rodape";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import ImputSelectMultParao from "../../../componentes/Inputs/ImputSelectMultPadrao"
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';
import { Global_Envia_Msg_WhatsApp, Global_Retorna_Permissoes, Global_Valida_Data, Global_Retorna_Data_Inicial_Final } from "../../../Funcoes_Globais"
import ContainerFiltro from "../../../componentes/ContainerFiltro";
import Estilos from "./estilo.module.css";
import { CoresGlobal } from "../../../estilos";

//<Link to={"/clientes/cadastro"}  state={{ testea: "value" }}>Teste com parâmetro</Link>
const Filtros_Boleto = [
    { value: "blt.dt_vencimento", label: "Boleto Dt.Vencimento", grupo:"tipo_data"},
    { value: "blt.dt_cadastro", label: "Boleto Dt.Cadastro", grupo:"tipo_data"},
    { value: "blt.dt_cancelamento", label: "Boleto Dt.Cancelamento", grupo:"tipo_data"},
    { value: "blt.dt_pagamento", label: "Boleto Dt.Pagamento", grupo:"tipo_data"},    

    { value: "blt.wp_enviado", label: "Boleto WhatsApp Enviado", grupo:"wp_situacao"},
    { value: "blt.wp_pendente", label: "Boleto WhatsApp Pendente", grupo:"wp_situacao"}, 

    { value: "blt.sit_aberto", label: "Boleto Aberto", grupo:"boleto_situacao"},
    { value: "blt.sit_cancelado", label: "Boleto Cancelado", grupo:"boleto_situacao"},
    { value: "blt.sit_pago", label: "Boleto Pago", grupo:"boleto_situacao"},
    { value: "blt.sit_vencido", label: "Boleto Vencido", grupo:"boleto_situacao"},
  ];

//const Filtros_Cliente = [
//    { value: "cli.razao", label: "Cliente Razão", grupo:"tipo_texto"},
//    { value: "cli.cnpj", label: "Cliente Cnpj", grupo:"tipo_texto"},
//];

const Grupo_Opcoes = [
    {label: "Boleto",options: Filtros_Boleto},
    //{label: "Cliente",options: Filtros_Cliente},
];

class CoraCobrancaConsulta extends Component{
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',

            State_Pesq_Dica: '',
            State_Pesq_Dt_Inicial: Global_Retorna_Data_Inicial_Final('inicial'),
            State_Pesq_Dt_Final: Global_Retorna_Data_Inicial_Final('final'),
            State_Pesq_Multi_Valor: [
                { value: "blt.dt_vencimento", label: "Boleto Dt.Vencimento", grupo:"tipo_data"},
                //{ value: "cli.razao", label: "Cliente Razão", grupo:"tipo_texto"},
            ],

            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),
            State_Permissao_excluir: Global_Retorna_Permissoes('config_perm', 'excluir'),

            State_Pesq_Cliente: '',             
            State_Pesq_Lista_Clientes: [],            
            State_Pesq_Cliente_Label_Padrao: undefined,
            State_Pesq_Cliente_value_Padrao: undefined,
            State_Pesq_Cliente_fk_empresa: null,
            State_Pesq_Cliente_razao: null,

            State_lista_revendas: [],
            
            State_lista_status_boletos: [
                {qtd: 0, status: 'OPEN', valor: 0.00},
                {qtd: 0, status: 'CANCELED', valor: 0.00},
                {qtd: 0, status: 'PAID', valor: 0.00},
                {qtd: 0, status: 'LATE', valor: 0.00},
                {qtd: 0, status: null, valor: 0.00},
            ],
            
            State_Paginacao_Primeira_Consulta: true,
            State_Paginacao_total: 9,
            State_Paginacao_Limit: 9,
            State_Paginacao_Offset: 0,  
            
            State_Cancelar_Boleto_Codigo: 0,
            State_Marcar_Pago_Codigo: 0,

        }

        this.Editar_Cobranca = this.Editar_Cobranca.bind(this);
        this.Ver_Cobranca = this.Ver_Cobranca.bind(this);
        this.Pesquisar_Cobranca = this.Pesquisar_Cobranca.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
    };   

    componentDidMount(){
        this.Pesquisar_Cobranca();
    } 

    handleMultiChange(filtro_atualizado, opcao_escolhida){
        var grupo_filtro_unico = ['tipo_data', 'tipo_texto', 'tipo_valor', 'wp_situacao'];
        var acao = opcao_escolhida.action
        var nova_lista = []

        if (acao === 'select-option'){
            if (grupo_filtro_unico.find((str) => str === opcao_escolhida.option.grupo)){
                nova_lista = filtro_atualizado.filter((item) => item.grupo !== opcao_escolhida.option.grupo);
                nova_lista = [...nova_lista, { 
                    "value": opcao_escolhida.option.value, 
                    "label": opcao_escolhida.option.label, 
                    "grupo":opcao_escolhida.option.grupo
                },];
                filtro_atualizado = nova_lista
            }
        }

        this.setState(state => {
          return {
            State_Pesq_Multi_Valor: filtro_atualizado,
            State_Paginacao_Primeira_Consulta: true,
          };
        });
    }

    Atualiza_Dt_Inicial(data_str){
        this.setState({
            State_Pesq_Dt_Inicial: Global_Valida_Data(data_str) ? data_str : null
        });
    }

    Atualiza_Dt_Final(data_str){
        this.setState({
            State_Pesq_Dt_Final: Global_Valida_Data(data_str) ? data_str : null
        });
    }

    Editar_Cobranca(boleto_id){
        this.props.navegar("/cora/boletos/cadastro", {state: { boleto_id: boleto_id, status_operacao: 'editar' } });
    } 
    
    Ver_Cobranca(boleto_id){
        this.props.navegar("/cora/boletos/cadastro", {state: { boleto_id: boleto_id, status_operacao: 'ver' } });
    }       

    Adicionar_Cobranca(){
        this.props.navegar("/cora/boletos/cadastro", {state: { boleto_id: 0, status_operacao: 'cadastrar' } });
    }

    Pergunta_Cancelar_Cobranca(codigo){
        this.setState({
            State_Cancelar_Boleto_Codigo: codigo 
        }, () => {
            this.Abre_MsgPopUpPadrao(2, true, 'Tem certeza que gostaria de cancelar este boleto?', 'Cancelar_Cobranca', 0)            
        });
    };    

    Cancelar_Cobranca(){
        /*
            O State Pesq_Pagi_Primeira_Consulta Precisa ser alterado no onchange do imput da consulta
            Se o retorno for  dois, jogar o state State_Paginacao_total: 9, State_Paginacao_Limit: 9, State_Paginacao_Offset: 0
        */

        if (!this.state.State_Cancelar_Boleto_Codigo || this.state.State_Cancelar_Boleto_Codigo <= 0){
            return
        }

        var objJson = { 
            boleto_id: this.state.State_Cancelar_Boleto_Codigo,
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Cancela_Boleto', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){                 
                this.Abre_MsgPopUpPadrao(1, false, 'Boleto Cancelado!', '', 2000);
                this.Pesquisar_Cobranca()                 
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, true, resposta.msg, '', 0)                         
            }
            else{
                this.Abre_MsgPopUpPadrao(5, true, resposta.msg, '', 0)
            };           
        });
    } 
    
    Pergunta_Marcar_Como_Pago(codigo){
        this.setState({
            State_Marcar_Pago_Codigo: codigo 
        }, () => {
            this.Abre_MsgPopUpPadrao(2, true, 'Tem certeza que gostaria de marcar este boleto como pago?', 'Marcar_Como_Pago', 0)            
        });        
    };  
    
    Marcar_Como_Pago(){
        var objJson = {
            boleto_id: this.state.State_Marcar_Pago_Codigo,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Marcar_Pago_Boleto', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Pesquisar_Cobranca();
                this.Abre_MsgPopUpPadrao(1, false, 'Boleto atualizado como pago!', '', 2000);
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, true, resposta.msg, '', 0)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }     

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Cancelar_Cobranca'){
                    this.Cancelar_Cobranca()
                }else if (this.state.State_MsgPopUpPadrao_Acao === 'Marcar_Como_Pago'){
                    this.Marcar_Como_Pago();
                };
            };
        });
    };   
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };

    Pesquisar_Cobranca(Offset = 0){
        /*
            O State Pesq_Pagi_Primeira_Consulta Precisa ser alterado no onchange do imput da consulta
            Se o retorno for  dois, jogar o state State_Paginacao_total: 9, State_Paginacao_Limit: 9, State_Paginacao_Offset: 0
        */
        var filtro_boleto_situacao = [] 
        var valida_data = false
        
        this.state.State_Pesq_Multi_Valor.forEach(function(item, index){
            if (item.grupo === 'boleto_situacao')
                filtro_boleto_situacao.push(item.value)
            if (item.grupo === 'tipo_data')
                valida_data = true            
        });        
        
        if (valida_data){
            if (!this.state.State_Pesq_Dt_Inicial){
                this.Abre_MsgPopUpPadrao(5, false, 'Data Inicial Inválida!', '', 1500)
                return; 
            }
            if (!this.state.State_Pesq_Dt_Final){
                this.Abre_MsgPopUpPadrao(5, false, 'Data Final Inválida!', '', 1500)
                return; 
            }                                   
        }

        this.setState({
            State_Paginacao_Offset: Offset,
            State_lista_revendas: []
        });

        var objJson = { 
            Pesq_fk_empresa: this.state.State_Pesq_Cliente_fk_empresa,
            Pesq_Dt_Inicial: this.state.State_Pesq_Dt_Inicial,
            Pesq_Dt_Final: this.state.State_Pesq_Dt_Final,
            Pesq_Filtro_Geral: this.state.State_Pesq_Multi_Valor,
            Pesq_Filtro_Boleto_Situacao: filtro_boleto_situacao,
            Pesq_Pagi_Offset: Offset,
            Pesq_Pagi_Limit: this.state.State_Paginacao_Limit,
            Pesq_Pagi_Primeira_Consulta: this.state.State_Paginacao_Primeira_Consulta, 
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Consulta_Boleto', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                //console.log(resposta.dados.inf_status)
                this.setState({
                    State_lista_revendas: resposta.dados.cobranca,
                }, function(){
                    if(this.state.State_Paginacao_Primeira_Consulta){
                        this.setState({
                            State_Paginacao_total: resposta.dados.qtd_paginas_calculadas, 
                            State_lista_status_boletos: resposta.dados.inf_status,
                            State_Paginacao_Primeira_Consulta: false,                            
                        });
                    };
                    //console.log('Total>', this.state.State_Paginacao_total);
                });
            }else if (resposta.status === 2){
                //this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)
                this.setState({
                    State_Paginacao_total: 9,
                    State_Paginacao_Limit: 9,
                    State_Paginacao_Offset: 1,
                });                          
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }
    
    ItemPaginacao_Click(codigo){
        this.Pesquisar_Cobranca(codigo);
    }

    Pesquisar_Cliente(Offset = 0){
        /*
            O State Pesq_Pagi_Primeira_Consulta Precisa ser alterado no onchange do imput da consulta
            Se o retorno for  dois, jogar o state State_Paginacao_total: 9, State_Paginacao_Limit: 9, State_Paginacao_Offset: 0
        */
        this.setState({
            //State_Paginacao_Offset: Offset,
            State_lista_clientes: []
        });

        var objJson = { 
            Pesq_Valor: this.state.State_Pesq_Cliente,
            Pesq_Pagi_Offset: Offset,
            Pesq_Pagi_Limit: 50,
            Pesq_Pagi_Primeira_Consulta: false, 
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cliente_Consulta', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                const itens_api = Object.entries(resposta.dados.cliente).map( ( item ) => ({
                    label: `${item[1].descricao} | ${item[1].cnpj}`, value:item[1].codigo,
                    razao: item[1].descricao,
                }));
                this.setState({
                    State_Pesq_Lista_Clientes: itens_api
                }, function(){
                    //console.log('Total>', this.state.State_Paginacao_total);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                         
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    } 
    
    Escolher_Cliente(codigo){
        var json = this.state.State_Pesq_Lista_Clientes.find(element => element.value === codigo)
        this.setState({
            State_Pesq_Cliente_fk_empresa: json.value,
            State_Pesq_Cliente_razao: json.razao,
        })
    }
    
    Envia_WhatsApp(codigo){
        var objJson = { 
            rotina: 'cora_boleto',
            cora_boleto_id: codigo ,
        }

        var rota = 'Gera_Inf_Msg_Whatsapp'

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, rota, 10000, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                Global_Envia_Msg_WhatsApp(resposta.dados.informacoes.fone, resposta.dados.informacoes.mensagem)              
                this.Abre_MsgPopUpPadrao(1, false, 'Mensagem Criada', '', 2000) 

                const novaLista = this.state.State_lista_revendas.map((ItemLista) =>{
                    //console.log('Codigo>>', ItemLista.codigo, codigo, ItemLista.whatsapp_enviado)
                    if (ItemLista.codigo === codigo){
                        return {...ItemLista, whatsapp_enviado: 'S'}
                    } else{
                        return ItemLista;
                    };             
                })
                
                this.setState({State_lista_revendas: novaLista})                      
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                        
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    } 
    
    Sincroniza_Situacao(){
        if (!this.state.State_Pesq_Dt_Inicial){
            this.Abre_MsgPopUpPadrao(5, false, 'Data Inicial Inválida!', '', 1500)
            return; 
        }
        if (!this.state.State_Pesq_Dt_Final){
            this.Abre_MsgPopUpPadrao(5, false, 'Data Final Inválida!', '', 1500)
            return; 
        }

        var objJson = { 
            Proc_Rotina: 'sinc_situacao_boletos', 
            Proc_Filtro_Data_Inicial: this.state.State_Pesq_Dt_Inicial,
            Proc_Filtro_Data_Final: this.state.State_Pesq_Dt_Final,
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Inicia_Processamento_Paralelo', 10000, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Acompanha_Sincroniza_Situacao();
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                        
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }
    
    Acompanha_Sincroniza_Situacao(){
        var objJson = { 
            Proc_Rotina: 'sinc_situacao_boletos',
        }

        Api.Executa_chamada(
            this.props.navegar, 'Acompanha_Processamento_Paralelo', 10000, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            if (resposta.status === 1){
                if (resposta.dados.status === 1){
                    this.Abre_LoadPopUpPadrao(resposta.dados.mensagem);
                    setTimeout(() => {
                        this.Acompanha_Sincroniza_Situacao()
                    }, "5000");
                }else if((resposta.dados.status === 2)){
                    this.Abre_MsgPopUpPadrao(1, true, 'Sincronização efetuada com sucesso', '', 0)
                    this.Fecha_LoadPopUpPadrao();
                }else{
                    this.Abre_MsgPopUpPadrao(5, true, resposta.dados.mensagem, '', 0)
                    this.Fecha_LoadPopUpPadrao();
                }
            }else if (resposta.status === 2){
                this.Fecha_LoadPopUpPadrao();
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                        
            }
            else{
                this.Fecha_LoadPopUpPadrao();
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }   
    
    Teste(){
        console.log('TESte')
        var objJson = { 
            rotina: 'cora_boleto',
            cora_boleto_id: 'inv_Lm6n1KsnSJWSPeRxbs99Stg' ,
            Pesq_Codigo: '1',
            Pesq_Ano: 2025,
        }

        var rota = 'Cora_Integracao_Paga_Boleto'
        //var rota = 'CoraIntegracaoConfigEndPoint_Del_Cadastra'
        //var rota = 'Cora_Teste_Post_Webhook'
        //var rota = 'Teste_1'

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, rota, 10000, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                console.log(resposta.dados)
                this.Abre_MsgPopUpPadrao(1, false, 'deu certo', '', 2000)     
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                        
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    } 

    Teste2(){
        if (!this.state.State_Pesq_Dt_Inicial){
            this.Abre_MsgPopUpPadrao(5, false, 'Data Inicial Inválida!', '', 1500)
            return; 
        }
        if (!this.state.State_Pesq_Dt_Final){
            this.Abre_MsgPopUpPadrao(5, false, 'Data Final Inválida!', '', 1500)
            return; 
        }

        var objJson = { 
            Proc_Rotina: 'sinc_situacao_boletos', 
            Proc_Filtro_Data_Inicial: this.state.State_Pesq_Dt_Inicial,
            Proc_Filtro_Data_Final: this.state.State_Pesq_Dt_Final,
        }

        //var rota = 'Cora_Integracao_Gera_Token'
        //var rota = 'CoraIntegracaoConfigEndPoint_Del_Cadastra'
        var rota = 'Inicia_Processamento_Paralelo'
        //var rota = 'CoraIntegracaoConfig_Lista_Notificacoes'

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, rota, 10000, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Abre_MsgPopUpPadrao(1, false, 'deu certo', '', 2000)     
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                        
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }
    
    Item_Pesquisa = (codigo, descricao, Itens, whatsapp_enviado) =>{
        var status_boleto = 'Status Desconhecido'
        var status_cor = CoresGlobal.cor_paleta_1
    
        if (Itens.pagamento_manual === 'S'){
            status_boleto = 'Pago Manualmente'
            status_cor = CoresGlobal.cor_paleta_4
        }else if (Itens.Sincronizado === 'N'){
            status_boleto = 'Não Gerado'
            status_cor = CoresGlobal.cor_paleta_1
        }else if (Itens.Cora_Status === 'OPEN'){
            status_boleto = 'Aberto'
            status_cor = CoresGlobal.cor_paleta_2
        }else if (Itens.Cora_Status === 'CANCELLED'){
            status_boleto = 'Cancelado'
            status_cor = CoresGlobal.cor_paleta_3
        }else if (Itens.Cora_Status === 'PAID'){
            status_boleto = 'Pago'
            status_cor = CoresGlobal.cor_paleta_4
        }else if (Itens.Cora_Status === 'LATE'){
            status_boleto = 'Vencido'
            status_cor = CoresGlobal.cor_paleta_8
        }
        else{
            status_boleto = 'Situação Desconhecida'
            status_cor = CoresGlobal.cor_paleta_7           
        }       
        return(
            <div className={Estilos.Item_Pesquisa} style={{borderLeft: `0.2em solid ${CoresGlobal.cor_paleta_1}`}}>
                <p style={{fontSize: '.8em', fontWeight: 'bold', color: CoresGlobal.cor_paleta_1}}>{descricao}</p>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0}}>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                        <div  style={{width: '100%'}}>
                            <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 2fr))', gap: '0em', justifyContent: 'space-between'}}>
                                <div style={{fontSize: '.7em'}}>
                                    <p><span style={{fontWeight: 'bold'}}>Vencimento</span>: {Itens.Vencimento}</p>
                                </div> 
                                <div style={{fontSize: '.7em'}}>
                                    <p><span style={{fontWeight: 'bold'}}>Valor</span>: {Itens.Valor}</p>
                                </div>                                         
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                    {
                            (this.state.State_Permissao_Editar && Itens.Sincronizado === 'N' && Itens.pagamento_manual === 'N') ?
                            <BotaoPadrao
                            estilo={{padding: '0 0.5em'}}
                                tipo={'cifrao'} 
                                onClick={() =>{this.Pergunta_Marcar_Como_Pago(codigo)}}
                            />                      
                            : null
                        }                          
                        {
                            (this.state.State_Permissao_ver && Itens.Sincronizado === 'S') ?
                            <BotaoPadrao
                                estilo={{padding: '0 0.5em'}}
                                tipo={'whatsapp'} 
                                onClick={() =>{this.Envia_WhatsApp(codigo)}}
                            />
                            : null                             
                        }                        
                        {
                            (this.state.State_Permissao_ver) ?
                            <BotaoPadrao
                                estilo={{padding: '0 0.5em'}}
                                tipo={'visualizar'} 
                                onClick={() =>{this.Ver_Cobranca(codigo)}}
                            />                        
                            : null
                        }
                        {
                            (this.state.State_Permissao_Editar && Itens.Sincronizado === 'N' && Itens.pagamento_manual === 'N') ?
                            <BotaoPadrao
                            estilo={{padding: '0 0.5em'}}
                                tipo={'editar'} 
                                onClick={() =>{this.Editar_Cobranca(codigo)}}
                            />                      
                            : null
                        }                         
                        {
                            (this.state.State_Permissao_excluir && Itens.Sincronizado === 'S' && Itens.Cora_Status === 'OPEN') ?
                            <BotaoPadrao
                                estilo={{padding: '0 0.5em'}}
                                tipo={'excluir'} 
                                onClick={() =>{this.Pergunta_Cancelar_Cobranca(codigo)}}
                            />                       
                            : null
                        }                                                                                                       
                    </div>                                        
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{backgroundColor: status_cor, fontWeight: 'bold', color: '#fff', padding: '0.2em', borderRadius: '2px', marginRight: '5px'}}>
                        {status_boleto}
                    </div>
                    {
                        whatsapp_enviado === 'S' ?
                        <div style={{display: 'flex', alignItems: 'center',backgroundColor: CoresGlobal.cor_paleta_9, fontWeight: 'bold', color: '#fff', padding: '0.2em', borderRadius: '2px', marginRight: '5px'}}>
                            <FaWhatsapp size={15} style={{marginRight: 4}}/>     
                            <FaCheck size={15} style={{marginRight: 4}}/>
                        </div>
                        : null
                    }                                                    
                </div>
            </div>
        )
    }

    Item_Status = (status, qtd, total) =>{
        var status_convertido = ''
        var status_cor = CoresGlobal.cor_paleta_1

        if (status === 'OPEN'){
            status_convertido = 'ABERTO'
            status_cor = CoresGlobal.cor_paleta_2
        }else if (status === 'CANCELLED'){
            status_convertido = 'CANCELADO'
            status_cor = CoresGlobal.cor_paleta_3
        }else if (status === 'PAID'){
            status_convertido = 'PAGO'
            status_cor = CoresGlobal.cor_paleta_4
        }else if (status === 'LATE'){
            status_convertido = 'VENCIDO'
            status_cor = CoresGlobal.cor_paleta_8
        }else{
            status_convertido = 'NÃO GERADO'
            status_cor = CoresGlobal.cor_paleta_1
        }

        return(
            <div style={{padding: '0px', width: '25%', minWidth: '90px'}}>
                <div style={{backgroundColor: status_cor, borderRadius: '5px 5px 0px 0px', color: '#fff', fontWeight: 'bold', padding: '5px', fontSize: '12px'}}>
                    {status_convertido}
                </div>
                <div style={{fontSize: '12px', fontWeight: 'bold', color: '#595959', border: `2px, solid, ${status_cor}`, borderRadius: '0px 0px 5px 5px', padding: '0px 10px 10px 10px'}}>
                    <div>
                        {qtd}
                    </div>
                    <div>
                        R$ {total}
                    </div>
                </div>
            </div>            
        )
    }

    render(){
        return(
        <div>               
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">
                    <TituloPagina titulo={"Cora Boletos"} />
                    <div style={{display: 'flex', marginBottom: '5px'}}>                       
                        {
                            this.state.State_Permissao_cadastrar ?
                            <BotaoPadrao
                                tipo={"adicionar"}
                                descricao={"Novo"}
                                onClick={() =>{this.Adicionar_Cobranca()}}
                                //onClick={() =>{this.Teste()}}
                            />
                            : null                            
                        }
                        <BotaoPadrao
                            tipo={"sincronizar"}
                            descricao={"Sincronizar Situação"}
                            onClick={() =>{this.Sincroniza_Situacao()}}
                        />
                        {
                            //<BotaoPadrao
                            //    tipo={"Teste"}
                            //    descricao={"Teste"}
                            //    onClick={() =>{this.Teste()}}
                            ///>   
                        }                                                                              
                    </div>                    
                    <div 
                        style={{width: '100%', overflowX: 'auto', scrollBehavior: 'smooth', marginBottom: '9px'}}
                    >
                        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                            {
                                this.state.State_lista_status_boletos.map((item) =>{
                                    return(
                                        this.Item_Status(item.status, item.qtd, item.valor)
                                    )
                                })
                            }
                        </div>
                    </div>                                       
                    <ContainerFiltro>
                        <div style={{marginBottom: '0.5em'}} className={Estilos.ContainerGrid2Colunas}>                         
                            <InputDataPadrao 
                                //estilo = {{'width': '120px'}}
                                estilo = {{'width': '100%'}}
                                titulo = 'Data Inicial'
                                value={this.state.State_Pesq_Dt_Inicial}
                                onChange={valor => {this.Atualiza_Dt_Inicial(valor.target.value); this.setState({State_Paginacao_Primeira_Consulta: true})}}                            
                                disabled = {false}
                                //disabled = {this.state.state_boleto_status_envio === 'S' ? true : false}
                            />  
                            <InputDataPadrao 
                                //estilo = {{'width': '120px'}}
                                estilo = {{'width': '100%'}}
                                titulo = 'Data Final'
                                value={this.state.State_Pesq_Dt_Final}
                                onChange={valor => {this.Atualiza_Dt_Final(valor.target.value);this.setState({State_Paginacao_Primeira_Consulta: true})}}    
                                //disabled = {this.state.state_boleto_status_envio === 'S' ? true : false}
                            />                                                                                                                                             
                        </div>                        
                        <InputSelectPadrao
                            estilo = {{'marginBottom': '0.5em'}}
                            titulo = {'Cliente'}
                            options={this.state.State_Pesq_Lista_Clientes}
                            onInputChange={valor => {this.setState({State_Pesq_Cliente: valor, State_Paginacao_Primeira_Consulta: true})}}
                            onKeyDown={
                                event => {
                                    //console.log('Key', event.key)
                                    if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                                         if (event.key === 'Enter' && this.state.State_Pesq_Lista_Clientes.length <= 0){
                                            this.Pesquisar_Cliente()
                                         }
                                    }else{
                                        this.setState({State_Pesq_Lista_Clientes:[], State_Pesq_Cliente_fk_empresa: null, State_Pesq_Cliente_razao: null})
                                    } 
                                }
                            }
                            value_label = {this.state.State_Pesq_Cliente_razao}
                            value_value = {this.state.State_Pesq_Cliente_fk_empresa}
                            onChange={valor => {this.Escolher_Cliente(valor.value) }}
                            placeholder = {'Pesquisar Cliente'}
                            //isDisabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_gerado_api_interna === 'S') ? true : false}
                        />                        
                        <ImputSelectMultParao
                            titulo = {'Extras'}
                            placeholder = {'Filtros Diversos'}
                            value={this.state.State_Pesq_Multi_Valor}
                            options={Grupo_Opcoes}
                            onChange={this.handleMultiChange} 
                        />
                    </ContainerFiltro>
                    <BotaoPadrao
                            estilo = {{'width': '100%'}}
                            tipo={"pesquisar"}
                            descricao={"Pesquisar"}
                            //onClick={() =>{this.Teste()}}
                            onClick={() =>{this.Pesquisar_Cobranca()}}
                    />
                    <TabelaTipo1
                        estilo = {{marginTop: '0px',}}
                        titulo = {''}
                    >
                        {
                        (this.state.State_lista_revendas && this.state.State_lista_revendas.length > 0) ?
                            this.state.State_lista_revendas.map((item) =>{
                                return(
                                    this.Item_Pesquisa(item.codigo, item.descricao, item.Itens, item.whatsapp_enviado)
                                )                            
                            })
                        :
                        <div 
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                            Nenhum boleto encontrado
                        </div>
                        }                       
                    </TabelaTipo1>  
                    <Paginacao 
                        limit={this.state.State_Paginacao_Limit}
                        total={this.state.State_Paginacao_total}
                        offset={this.state.State_Paginacao_Offset}
                        setOffset={this.Pesquisar_Cobranca}                        
                    />
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(CoraCobrancaConsulta);