import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import ImputNumeroPadrao from "../../../componentes/Inputs/ImputNumeroPadrao"
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
//import InputDataPadrao from "../../../componentes/Inputs/InputDataPadrao";
import TooglePadrao from "../../../componentes/Botoes/TooglePadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import TituloCadastroDetalhe from "../../../componentes/Titulos/TituloCadatrosDetalhe";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import TabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao";
import TabelaTipo1 from "../../../componentes/Tabelas/TabelaTipo1";
import ComboBoxPadrao from "../../../componentes/ComboBox/ComboboxPadrão"
import Rodape from "../../../componentes/Rodape";
import { Global_Valida_Data, Global_Retorna_Permissoes } from "../../../Funcoes_Globais";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';



//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class CoraConfig extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',
            
            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),                        

            state_lista_revendas: [],
            state_lista_pcf: [],
            state_lista_pcf_entrada: [],
            state_lista_pcf_taxa_cancelamento: [],
            state_lista_pcf_taxa_recebimento: [],
            state_lista_webhook_resource: [
                {codigo: 1, descricao: 'Invoice'},
            ],
            state_lista_webhook_trigger: [
                {codigo: 1, descricao: 'Todas'},
                {codigo: 2, descricao: 'Drafted'},
                {codigo: 3, descricao: 'Created'},
                {codigo: 4, descricao: 'Paid'},
                {codigo: 5, descricao: 'Canceled'},
                {codigo: 6, descricao: 'Overdue'},
            ],
            state_webhook_url: null,
            state_webhook_resource_id: 0,
            state_webhook_trigger_id: 0,
                    
            state_cora_cliente_id: null,
            state_cora_base_url: null,
            state_cora_access_token: null,
            state_cora_multa_vencimento: 0,
            state_cora_juros_mensal: 0,
            state_cora_desconto_pgto_antecipado: 0,
            state_cora_tarifa_pgto_boleto: 0,
            state_cora_tarifa_canc_boleto: 0,
            state_cora_notifica_por_email: 'N',
            state_cora_notifica_por_sms: 'N',
            state_cora_notifica_15dias_antes_vencimento: 'N',
            state_cora_notifica_10dias_antes_vencimento: 'N',
            state_cora_notifica_7dias_antes_vencimento: 'N',
            state_cora_notifica_5dias_antes_vencimento: 'N',
            state_cora_notifica_2dias_antes_vencimento: 'N',
            state_cora_notifica_nodia_do_vencimento: 'N',
            state_cora_notifica_2dias_depois_vencimento: 'N',
            state_cora_notifica_5dias_depois_vencimento: 'N',
            state_cora_notifica_7dias_depois_vencimento: 'N',
            state_cora_notifica_10dias_depois_vencimento: 'N',
            state_cora_notifica_15dias_depois_vencimento: 'N',
            state_cora_notifica_boleto_pago: 'N',
            state_cora_lista_webhook_url: [],
            state_cora_fk_pcf_cancelamento: 0,
            state_cora_fk_pcf_taxa_recebimento: 0,

        };

        this.Escolhe_Webhook_Trigger = this.Escolhe_Webhook_Trigger.bind(this)
        this.Escolhe_Webhook_Resource = this.Escolhe_Webhook_Resource.bind(this)
        this.Sincronizar_Webhook_Url = this.Sincronizar_Webhook_Url.bind(this)
        this.Excluir_Webhook_Url = this.Excluir_Webhook_Url.bind(this)
        this.Escolhe_Plano_Contas_Cancelamento = this.Escolhe_Plano_Contas_Cancelamento.bind(this)
        this.Escolhe_Plano_Contas_Taxa_Recebimento = this.Escolhe_Plano_Contas_Taxa_Recebimento.bind(this)
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.cliente_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_cliente_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais()                  
    };
    
    Funcoes_Iniciais = async () =>{
        await this.Retorna_Listar_Diversas();
        this.Pesquisar_CoraConfig(); 
    } 
    
    Filtra_Plano_Contas_Entrada(cod_natureza = 1){
        console.log(this.state.state_lista_pcf)
        const newList = this.state.state_lista_pcf.filter((item) => item.natureza === Number(cod_natureza));
        this.setState({
            state_lista_pcf_entrada: newList,
        }, function (){
            //
        });         
    } 
    
    Filtra_Plano_Contas_Saída(cod_natureza = 2){
        console.log(this.state.state_lista_pcf)
        const newList = this.state.state_lista_pcf.filter((item) => item.natureza === Number(cod_natureza));
        this.setState({
            state_lista_pcf_taxa_cancelamento: newList,
            state_lista_pcf_taxa_recebimento: newList,
        }, function (){
            //
        });         
    }    
    
    Escolhe_Plano_Contas_Cancelamento(e){
        e.preventDefault();      
        this.setState({
            state_cora_fk_pcf_cancelamento:  parseInt(e.target.value),
        }, function(){
            //this.Filtra_Permissao(this.state.state_usuario_cod_revenda);
        }
    );
    }; 
    
    Escolhe_Plano_Contas_Taxa_Recebimento(e){
        e.preventDefault();      
        this.setState({
            state_cora_fk_pcf_taxa_recebimento:  parseInt(e.target.value),
        }, function(){
            //this.Filtra_Permissao(this.state.state_usuario_cod_revenda);
        }
    );
    };     
    
    Retorna_Listar_Diversas(){
        return new Promise((resolve) => {
            var objJson = { 
                retorna_plano_contas_financeiro_todos: true,  
            };
    
            this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
            Api.Executa_chamada(
                this.props.navegar, 'Retorna_Listas_Diversas', 0, JSON.stringify(objJson)
            ).then((resposta) => {
                resolve(true);
                this.Fecha_LoadPopUpPadrao();
                if (resposta.status === 1){
                    //console.log(resposta.dados.revendas)
                    //console.log(resposta.dados)
                    this.setState({
                        state_lista_pcf: resposta.dados.plano_contas_financeiro,
                    }, function (){
                        this.Filtra_Plano_Contas_Entrada()
                        this.Filtra_Plano_Contas_Saída()
                    });
                }else if (resposta.status === 2){
                    this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
                }
                else{
                    this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
                };           
            });            
        });
    };  

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Excluir_Terminal'){
                    //this.Excluir_Terminal(this.state.state_excluir_terminal_codigo);
                };
            };
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };     

    Pesquisar_CoraConfig(){
        var objJson = { 
            Pesq_Codigo: this.state.state_config_id,
        };
        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Config_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {

            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_cora_cliente_id: resposta.dados.config.cora_cliente_id,
                    state_cora_base_url: resposta.dados.config.cora_base_url,
                    state_cora_multa_vencimento: resposta.dados.config.cora_multa_vencimento,
                    state_cora_juros_mensal: resposta.dados.config.cora_juros_mensal,
                    state_cora_desconto_pgto_antecipado: resposta.dados.config.cora_desconto_pgto_antecipado,
                    state_cora_tarifa_pgto_boleto: resposta.dados.config.cora_tarifa_pgto_boleto,
                    state_cora_tarifa_canc_boleto: resposta.dados.config.cora_tarifa_canc_boleto,
                    state_cora_notifica_por_email: resposta.dados.config.cora_notifica_por_email,
                    state_cora_notifica_por_sms: resposta.dados.config.cora_notifica_por_sms,
                    state_cora_notifica_15dias_antes_vencimento: resposta.dados.config.cora_notifica_15dias_antes_vencimento,
                    state_cora_notifica_10dias_antes_vencimento: resposta.dados.config.cora_notifica_10dias_antes_vencimento,
                    state_cora_notifica_7dias_antes_vencimento: resposta.dados.config.cora_notifica_7dias_antes_vencimento,
                    state_cora_notifica_5dias_antes_vencimento: resposta.dados.config.cora_notifica_5dias_antes_vencimento,
                    state_cora_notifica_2dias_antes_vencimento: resposta.dados.config.cora_notifica_2dias_antes_vencimento,
                    state_cora_notifica_nodia_do_vencimento: resposta.dados.config.cora_notifica_nodia_do_vencimento,
                    state_cora_notifica_2dias_depois_vencimento: resposta.dados.config.cora_notifica_2dias_depois_vencimento,
                    state_cora_notifica_5dias_depois_vencimento: resposta.dados.config.cora_notifica_5dias_depois_vencimento,
                    state_cora_notifica_7dias_depois_vencimento: resposta.dados.config.cora_notifica_7dias_depois_vencimento,
                    state_cora_notifica_10dias_depois_vencimento: resposta.dados.config.cora_notifica_10dias_depois_vencimento,
                    state_cora_notifica_15dias_depois_vencimento: resposta.dados.config.cora_notifica_15dias_depois_vencimento,
                    state_cora_notifica_boleto_pago: resposta.dados.config.cora_notifica_boleto_pago,
                    state_cora_lista_webhook_url: resposta.dados.webhook_url,
                    state_cora_fk_pcf_cancelamento: resposta.dados.config.cora_fk_pcf_cancelamento,
                    state_cora_fk_pcf_taxa_recebimento: resposta.dados.config.cora_fk_pcf_taxa_recebimento,
                }, function (){
                    //console.log('Teste>>', this.state.state_cora_lista_webhook_url.length)
                });
            }else if (resposta.status === 2){
                //this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_CoraConfig(){
        if (this.state.state_cora_tarifa_pgto_boleto <= 0) {
            this.Abre_MsgPopUpPadrao(3, false, 'Informao valor da tarifa por boleto pago!', '', 1500)
            return;            
        };
        if (this.state.state_cora_tarifa_canc_boleto <= 0) {
            this.Abre_MsgPopUpPadrao(3, false, 'Informe valor da tarifa por boleto cancelado!', '', 1500)
            return;            
        };                    
        if (!this.state.state_cora_fk_pcf_taxa_recebimento || this.state.state_cora_fk_pcf_taxa_recebimento <= 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Escolha o Plano de Contas Taxa Recebimento!', '', 1500);
            return
        } 

        if (!this.state.state_cora_fk_pcf_cancelamento || this.state.state_cora_fk_pcf_cancelamento <= 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Escolha o Plano de Contas Cancelamento!', '', 1500);
            return
        }       

        //state_cora_fk_pcf_cancelamento

        var objJson = {
            cora_cliente_id: this.state.state_cora_cliente_id,
            cora_base_url: this.state.state_cora_base_url,
            cora_multa_vencimento: this.state.state_cora_multa_vencimento,
            cora_juros_mensal: this.state.state_cora_juros_mensal,
            cora_desconto_pgto_antecipado: this.state.state_cora_desconto_pgto_antecipado,
            cora_tarifa_pgto_boleto : this.state.state_cora_tarifa_pgto_boleto,             
            cora_tarifa_canc_boleto: this.state.state_cora_tarifa_canc_boleto,
            cora_notifica_por_email: this.state.state_cora_notifica_por_email,
            cora_notifica_por_sms: this.state.state_cora_notifica_por_sms,
            cora_notifica_15dias_antes_vencimento: this.state.state_cora_notifica_15dias_antes_vencimento,
            cora_notifica_10dias_antes_vencimento: this.state.state_cora_notifica_10dias_antes_vencimento,
            cora_notifica_7dias_antes_vencimento: this.state.state_cora_notifica_7dias_antes_vencimento,
            cora_notifica_5dias_antes_vencimento: this.state.state_cora_notifica_5dias_antes_vencimento,
            cora_notifica_2dias_antes_vencimento: this.state.state_cora_notifica_2dias_antes_vencimento,
            cora_notifica_nodia_do_vencimento: this.state.state_cora_notifica_nodia_do_vencimento,
            cora_notifica_2dias_depois_vencimento: this.state.state_cora_notifica_2dias_depois_vencimento,
            cora_notifica_5dias_depois_vencimento: this.state.state_cora_notifica_5dias_depois_vencimento,
            cora_notifica_7dias_depois_vencimento: this.state.state_cora_notifica_7dias_depois_vencimento,
            cora_notifica_10dias_depois_vencimento: this.state.state_cora_notifica_10dias_depois_vencimento,
            cora_notifica_15dias_depois_vencimento: this.state.state_cora_notifica_15dias_depois_vencimento,
            cora_notifica_boleto_pago: this.state.state_cora_notifica_boleto_pago,
            cora_fk_pcf_cancelamento: this.state.state_cora_fk_pcf_cancelamento,
            cora_fk_pcf_taxa_recebimento: this.state.state_cora_fk_pcf_taxa_recebimento,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_Config_Atu_Cadastra', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Pesquisar_CoraConfig();
                this.Abre_MsgPopUpPadrao(1, false, 'Configuração atualizada com sucesso!', '', 2000);
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Salva_Webhook_Url(){
        if (!this.state.state_webhook_url || this.state.state_webhook_url.trim() === ''){
            this.Abre_MsgPopUpPadrao(5, false, 'Informa a Url do Webhoook', '', 2000)
            return            
        }
        if (!this.state.state_webhook_resource_id || this.state.state_webhook_resource_id === 0){
            this.Abre_MsgPopUpPadrao(5, false, 'Informa o Resource do Webhoook', '', 2000)
            return            
        }
        if (!this.state.state_webhook_trigger_id || this.state.state_webhook_trigger_id === 0){
            this.Abre_MsgPopUpPadrao(5, false, 'Informa a Trigger do Webhoook', '', 2000)
            return            
        } 
        
        var objJson = {
            cora_webhook_url: this.state.state_webhook_url,
            cora_webhook_resource_id: this.state.state_webhook_resource_id,
            cora_webhook_trigger_id: this.state.state_webhook_trigger_id,
        };
        
        this.Abre_LoadPopUpPadrao('Adicionando WebHook  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_ConfigWebhook_Cadastra', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Pesquisar_CoraConfig();
                this.Abre_MsgPopUpPadrao(1, false, 'WebHook adicionado com sucesso!', '', 2000);
                this.setState({
                    state_webhook_url: '',
                    state_webhook_resource_id: 0,
                    state_webhook_trigger_id: 0,
                })
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Sincronizar_Webhook_Url(){
        var objJson = {
        };
        
        this.Abre_LoadPopUpPadrao('Sincronizando WebHook  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_ConfigWebhook_Gera', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Pesquisar_CoraConfig();
                this.Abre_MsgPopUpPadrao(1, false, 'WebHook Sincronizado com sucesso!', '', 2000);
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }
    
    Excluir_Webhook_Url(codigo){
        var objJson = {
            webhook_id: codigo,
        };
        
        this.Abre_LoadPopUpPadrao('Excluindo WebHook  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cora_Integracao_ConfigWebhook_Exclui', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.Pesquisar_CoraConfig();
                this.Abre_MsgPopUpPadrao(1, false, 'WebHook Excluido com sucesso!', '', 2000);
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }
    
    Atualiza_Validade_Licensa(data_str){
        if (Global_Valida_Data(data_str)){
            this.setState({state_cliente_validade_licenca: data_str});
        }
    };

    Escolhe_Webhook_Resource(e){
        e.preventDefault();
        this.setState({
            state_webhook_resource_id: parseInt(e.target.value)
        });
    }    

    Escolhe_Webhook_Trigger(e){
        e.preventDefault();
        this.setState({
            state_webhook_trigger_id: parseInt(e.target.value)
        });
    }
        
    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Cora > Configurações"} />
                    <TituloCadastroDetalhe titulo={"Geral"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao
                                titulo={'Cliente-ID'}
                                onChange = {valor => this.setState({state_cora_cliente_id: valor.target.value})}
                                value = {this.state.state_cora_cliente_id}    
                        />

                        <InputPadrao
                                titulo={'URL Base'}
                                onChange = {valor => this.setState({state_cora_base_url: valor.target.value})}
                                value = {this.state.state_cora_base_url}    
                        />
                        <ImputNumeroPadrao
                            titulo = 'Multa Após Vencimento'
                            prefix = 'R$ '
                            value={this.state.state_cora_multa_vencimento}
                            onValueChange = {valor => this.setState({state_cora_multa_vencimento: valor.value})}
                        /> 
                        <ImputNumeroPadrao
                            titulo = 'Juros Mensal'
                            prefix = '% '
                            value={this.state.state_cora_juros_mensal}
                            onValueChange = {valor => this.setState({state_cora_juros_mensal: valor.value})}
                        />
                        <ImputNumeroPadrao
                            titulo = 'Desconto Pagto Antecipado'
                            prefix = '% '
                            value={this.state.state_cora_desconto_pgto_antecipado}
                            onValueChange = {valor => this.setState({state_cora_desconto_pgto_antecipado: valor.value})}
                        />
                        <ImputNumeroPadrao
                            titulo = 'Tarifa por boleto pago'
                            prefix = 'R$ '
                            value={this.state.state_cora_tarifa_pgto_boleto}
                            onValueChange = {valor => this.setState({state_cora_tarifa_pgto_boleto: valor.value})}
                        />
                        <ImputNumeroPadrao
                            titulo = 'Tarifa por boleto cancelado'
                            prefix = 'R$ '
                            value={this.state.state_cora_tarifa_canc_boleto}
                            onValueChange = {valor => this.setState({state_cora_tarifa_canc_boleto: valor.value})}
                        />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                    </div>
                    <TituloCadastroDetalhe titulo={"URLs Webhook"} /> 
                    <TabelaTipo1
                        estilo = {{marginTop: '0px', minHeight: 'none'}}
                        titulo = {'Cadastrar Urls'}
                    >
                        <div className={Estilos.ContainerGrid}> 
                            <InputPadrao
                                titulo={'URL'}
                                onChange = {valor => this.setState({state_webhook_url: valor.target.value})}
                                value = {this.state.state_webhook_url}    
                            />
                            <ComboBoxPadrao
                                titulo = {'Resource'}
                                dica = {'Nenhum'}
                                itens = {this.state.state_lista_webhook_resource}
                                onChange = {this.Escolhe_Webhook_Resource}
                                select_value = {this.state.state_webhook_resource_id}
                            />
                            <ComboBoxPadrao
                                titulo = {'Trigger'}
                                dica = {'Nenhum'}
                                itens = {this.state.state_lista_webhook_trigger}
                                onChange = {this.Escolhe_Webhook_Trigger}
                                select_value = {this.state.state_webhook_trigger_id}
                            />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                        </div>
                            <BotaoPadrao
                                estilo={{width: '100%'}}
                                tipo={"adicionar"} 
                                descricao={"Adicionar"} 
                                onClick={() =>{this.Salva_Webhook_Url()}} 
                            />                                                 
                    </TabelaTipo1>                                                           
                    <TabelaPadrao
                        estilo = {{marginTop: '0px', height: '13em'}}
                        transbordarY={true}
                        estilo2 = {{marginTop: '5px'}}
                        titulo={'Lista de Urls Cadastradas'}
                        Itens = {this.state.state_cora_lista_webhook_url}
                        motrarBtnExcluir = {(this.state.State_Permissao_Editar)}
                        clickExcluir ={this.Excluir_Webhook_Url}
                    />
                    <BotaoPadrao
                            estilo ={{width: '100%'}}
                            tipo={"sincronizar"} 
                            descricao={"Sincronizar Webhooks"} 
                            onClick={() =>{this.Sincronizar_Webhook_Url()}} 
                    />                                                              
                    <TituloCadastroDetalhe titulo={"Forma de Notificação"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <TooglePadrao
                            descricao={'Email'}
                            marcado={this.state.state_cora_notifica_por_email}
                            onClick={() => this.setState({state_cora_notifica_por_email: (this.state.state_cora_notifica_por_email === 'S') ? 'N' : 'S'})}
                        />  
                        <TooglePadrao
                            descricao={'SMS'}
                            marcado={this.state.state_cora_notifica_por_sms}
                            onClick={() => this.setState({state_cora_notifica_por_sms: (this.state.state_cora_notifica_por_sms === 'S') ? 'N' : 'S'})}
                        />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                    </div> 
                    <TituloCadastroDetalhe titulo={"Tipo de Notificação"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <TooglePadrao
                            descricao={'15 dias antes do vencimento'}
                            marcado={this.state.state_cora_notifica_15dias_antes_vencimento}
                            onClick={() => this.setState({state_cora_notifica_15dias_antes_vencimento: (this.state.state_cora_notifica_15dias_antes_vencimento === 'S') ? 'N' : 'S'})}
                        /> 
                        <TooglePadrao
                            descricao={'10 dias antes do vencimento'}
                            marcado={this.state.state_cora_notifica_10dias_antes_vencimento}
                            onClick={() => this.setState({state_cora_notifica_10dias_antes_vencimento: (this.state.state_cora_notifica_10dias_antes_vencimento === 'S') ? 'N' : 'S'})}
                        /> 
                        <TooglePadrao
                            descricao={'7 dias antes do vencimento'}
                            marcado={this.state.state_cora_notifica_7dias_antes_vencimento}
                            onClick={() => this.setState({state_cora_notifica_7dias_antes_vencimento: (this.state.state_cora_notifica_7dias_antes_vencimento === 'S') ? 'N' : 'S'})}
                        /> 
                        <TooglePadrao
                            descricao={'5 dias antes do vencimento'}
                            marcado={this.state.state_cora_notifica_5dias_antes_vencimento}
                            onClick={() => this.setState({state_cora_notifica_5dias_antes_vencimento: (this.state.state_cora_notifica_5dias_antes_vencimento === 'S') ? 'N' : 'S'})}
                        /> 
                        <TooglePadrao
                            descricao={'2 dias antes do vencimento'}
                            marcado={this.state.state_cora_notifica_2dias_antes_vencimento}
                            onClick={() => this.setState({state_cora_notifica_2dias_antes_vencimento: (this.state.state_cora_notifica_2dias_antes_vencimento === 'S') ? 'N' : 'S'})}
                        /> 
                        <TooglePadrao
                            descricao={'No dia do vencimento'}
                            marcado={this.state.state_cora_notifica_nodia_do_vencimento}
                            onClick={() => this.setState({state_cora_notifica_nodia_do_vencimento: (this.state.state_cora_notifica_nodia_do_vencimento === 'S') ? 'N' : 'S'})}
                        /> 
                        <TooglePadrao
                            descricao={'2 dias após o vencimento'}
                            marcado={this.state.state_cora_notifica_2dias_depois_vencimento}
                            onClick={() => this.setState({state_cora_notifica_2dias_depois_vencimento: (this.state.state_cora_notifica_2dias_depois_vencimento === 'S') ? 'N' : 'S'})}
                        />
                        <TooglePadrao
                            descricao={'5 dias após o vencimento'}
                            marcado={this.state.state_cora_notifica_5dias_depois_vencimento}
                            onClick={() => this.setState({state_cora_notifica_5dias_depois_vencimento: (this.state.state_cora_notifica_5dias_depois_vencimento === 'S') ? 'N' : 'S'})}
                        />
                        <TooglePadrao
                            descricao={'7 dias após o vencimento'}
                            marcado={this.state.state_cora_notifica_7dias_depois_vencimento}
                            onClick={() => this.setState({state_cora_notifica_7dias_depois_vencimento: (this.state.state_cora_notifica_7dias_depois_vencimento === 'S') ? 'N' : 'S'})}
                        />                        
                        <TooglePadrao
                            descricao={'10 dias após o vencimento'}
                            marcado={this.state.state_cora_notifica_10dias_depois_vencimento}
                            onClick={() => this.setState({state_cora_notifica_10dias_depois_vencimento: (this.state.state_cora_notifica_10dias_depois_vencimento === 'S') ? 'N' : 'S'})}
                        />
                        <TooglePadrao
                            descricao={'15 dias após o vencimento'}
                            marcado={this.state.state_cora_notifica_15dias_depois_vencimento}
                            onClick={() => this.setState({state_cora_notifica_15dias_depois_vencimento: (this.state.state_cora_notifica_15dias_depois_vencimento === 'S') ? 'N' : 'S'})}
                        />
                        <TooglePadrao
                            descricao={'Boleto pago'}
                            marcado={this.state.state_cora_notifica_boleto_pago}
                            onClick={() => this.setState({state_cora_notifica_boleto_pago: (this.state.state_cora_notifica_boleto_pago === 'S') ? 'N' : 'S'})}
                        />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                    </div>
                    <TituloCadastroDetalhe titulo={"Plano de Contas Padrão"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <ComboBoxPadrao
                            titulo = {'*Plano de Contas Taxa Recebimento'}
                            dica = {'Nenhum'}
                            itens = {this.state.state_lista_pcf_taxa_cancelamento}
                            onChange = {this.Escolhe_Plano_Contas_Taxa_Recebimento}
                            select_value = {this.state.state_cora_fk_pcf_taxa_recebimento}
                            //disabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                        />                     
                        <ComboBoxPadrao
                            titulo = {'*Plano de Contas Taxa Cancelamento'}
                            dica = {'Nenhum'}
                            itens = {this.state.state_lista_pcf_taxa_cancelamento}
                            onChange = {this.Escolhe_Plano_Contas_Cancelamento}
                            select_value = {this.state.state_cora_fk_pcf_cancelamento}
                            //disabled = {(this.state.state_boleto_sincronizado === 'S' || this.state.state_boleto_pagamento_manual === 'S') ? true : false}
                        />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                    </div>                                                                                                                
               
                    <div className={Estilos.ConteinerBotoes} >
                        {
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar)) ?
                            <BotaoPadrao
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_CoraConfig()}} 
                            />
                            : null
                        }                 
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(CoraConfig);