import React, { Component } from "react";
import BarraLateral from "../../../../componentes/BarraLateral";
import InputPadrao from "../../../../componentes/Inputs/ImputPadrao";
import TituloPagina from "../../../../componentes/TituloPagina";
import BotaoPadrao from "../../../../componentes/Botoes/BotaoPadrao";
import MsgPopUpPadrao from "../../../../componentes/PopUpModal/MsgPopUpPadrao";
import TabelaPadrao from "../../../../componentes/Tabelas/TabelaPadrao";
import Paginacao from "../../../../componentes/Tabelas/Paginacao";
import Rodape from "../../../../componentes/Rodape";
import withRouter from "../../../../Rotas/rotasParametros";
import Api from '../../../../servicos/Axios';
import PopUpLoadPadrao from '../../../../componentes/PopUpLoad/PopUpLoadPadrao';
import { Global_Retorna_Permissoes } from "../../../../Funcoes_Globais"

//<Link to={"/clientes/cadastro"}  state={{ testea: "value" }}>Teste com parâmetro</Link>

class PlanoContasResultadoConsulta extends Component{
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',

            State_Pesq_Valor: '',

            State_Permissao_Editar: Global_Retorna_Permissoes('config_perm', 'editar'),
            State_Permissao_ver: Global_Retorna_Permissoes('config_perm', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('config_perm', 'cadastrar'),

            State_lista_Planos: [],  
            
            State_Paginacao_Primeira_Consulta: true,
            State_Paginacao_total: 9,
            State_Paginacao_Limit: 9,
            State_Paginacao_Offset: 0,
        }

        this.Editar_Plano = this.Editar_Plano.bind(this);
        this.Ver_Plano = this.Ver_Plano.bind(this);
        this.Pesquisar_Plano = this.Pesquisar_Plano.bind(this);
    };   

    componentDidMount(){
        this.Pesquisar_Plano();
    }

    Editar_Plano(plano_id){
        this.props.navegar("/plano_de_contas/conta_resultado/cadastro", {state: { plano_id: plano_id, status_operacao: 'editar' } });
    } 
    
    Ver_Plano(plano_id){
        this.props.navegar("/plano_de_contas/conta_resultado/cadastro", {state: { plano_id: plano_id, status_operacao: 'ver' } });
    }       

    Adicionar_Plano(){
       this.props.navegar("/plano_de_contas/conta_resultado/cadastro", {state: { plano_id: 0, status_operacao: 'cadastrar' } });
    }

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };

    Pesquisar_Plano(Offset = 0){
        /*
            O State Pesq_Pagi_Primeira_Consulta Precisa ser alterado no onchange do imput da consulta
            Se o retorno for  dois, jogar o state State_Paginacao_total: 9, State_Paginacao_Limit: 9, State_Paginacao_Offset: 0
        */
        this.setState({
            State_Paginacao_Offset: Offset,
            State_lista_Planos: []
        });

        var objJson = { 
            Pesq_Valor: this.state.State_Pesq_Valor,
            Pesq_Pagi_Offset: Offset,
            Pesq_Pagi_Limit: this.state.State_Paginacao_Limit,
            Pesq_Pagi_Primeira_Consulta: this.state.State_Paginacao_Primeira_Consulta, 
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Plano_Contas_Resultado_Consulta', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    State_lista_Planos: resposta.dados.cliente,
                }, function(){
                    if(this.state.State_Paginacao_Primeira_Consulta){
                        this.setState({
                            State_Paginacao_total: resposta.dados.qtd_paginas_calculadas, 
                            State_Paginacao_Primeira_Consulta: false,                            
                        });
                    };
                    //console.log('Total>', this.state.State_Paginacao_total);
                });
            }else if (resposta.status === 2){
                //this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)
                this.setState({
                    State_Paginacao_total: 9,
                    State_Paginacao_Limit: 9,
                    State_Paginacao_Offset: 1,
                });                          
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }
    
    ItemPaginacao_Click(codigo){
        this.Pesquisar_Plano(codigo);
    }

    render(){
        return(
        <div>    
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Plano de Contas Resultado"} />
                    <div> 
                        {
                            this.state.State_Permissao_cadastrar ?
                            <BotaoPadrao
                                tipo={"adicionar"}
                                descricao={"Novo"}
                                onClick={() =>{this.Adicionar_Plano()}}
                            />
                            : null                            
                        }
                    </div>                                     
                    <InputPadrao 
                        //estilo={{ width:'100%', "margin-left": '10px'}}
                        //titulo={'Pesquisar'}
                        dica = {'Razão ou Cnpj'}
                        value = {this.state.State_Pesq_Valor}
                        tipo = {'pesquisa'}
                        onClick={() =>{this.Pesquisar_Plano()}}                    
                        onChange = {valor => this.setState({State_Pesq_Valor: valor.target.value, State_Paginacao_Primeira_Consulta:true})}
                        onKeyUp={event => {if (event.key === 'Enter') {this.Pesquisar_Plano()}}}
                        //autoFocus = {true}
                    />
                    <TabelaPadrao
                        estilo = {{marginTop: '0px'}}
                        //titulo={''}
                        mensagemItemVazio = {'Nenhum Plano de Contas Encontrado'}
                        Itens = {this.state.State_lista_Planos}
                        motrarBtnEditar={this.state.State_Permissao_Editar}
                        motrarBtnVisualizar={this.state.State_Permissao_ver}
                        motrarBtnExcluir = {false}
                        clickEditar={this.Editar_Plano}
                        clickVisualizar={this.Ver_Plano}
                    />   
                    <Paginacao 
                        limit={this.state.State_Paginacao_Limit}
                        total={this.state.State_Paginacao_total}
                        offset={this.state.State_Paginacao_Offset}
                        setOffset={this.Pesquisar_Plano}                        
                    />

                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(PlanoContasResultadoConsulta);